.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.react-content {
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.cls-1 {
  isolation: isolate;
  font-size: 78px;
  font-family: EurostileBQ-BoldExtended, Eurostile BQ;
  font-weight: 700;
}

.represocial-logo {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 30px;
}

.text {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.btn {
  width: 220px;
  border-radius: 10px;
  height: 48px;
  background-color: black;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
  border-style: none;
  cursor: pointer;
  margin: 20px auto 20px;
}

.react-btn {
  width: fit-content;
  margin: 20px auto 30px;
}

.react-content h1,
.react-content h2 {
  font-weight: 600;
}

.react-content h1 {
  font-size: 21px;
  margin-top: 35px;
  margin-bottom: 30px;
}

.react-content h2 {
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.footer {
  margin-top: 45px;
  margin-bottom:40px;
}

.footer a {
  color: black;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.react-text {
  max-width: 560px;
  font-size: 14px;
  line-height: 22px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 320px) and (max-width: 340px) {
  .react-text {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 480px) {
  .react-text {
    max-width: 340px;
    font-size: 13px;
    margin-left: auto;
    margin-right: auto;
  }
}

.responsive-youtube {
  width: 480px;
  height: 315px;
  margin: 20px auto 60px;
}
@media (min-width: 0) and (max-width: 480px) {
  .responsive-youtube {
    width: 100%;
    height: 198px;
  }
}

.react-component {
  margin-bottom: 40px;
}

.react-content {
  display: block;
  padding-right: 30px;
  padding-left: 30px;
}

.react-signup {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-family: "Montserrat",sans-serif;
  font-weight: 600;
}

.benefits-list {
  text-align:left;
}

.benefits-list li {
margin-bottom:10px;
}

.offer-icon {
  font-size: 25px;
}

.socials svg {
  margin-right:10px;
  margin-left:10px;
}

.sub-heading {
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}

